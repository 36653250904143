import AgentRoutes from '@/modules/agent/routes';
import OpportunityAtlasRoutes from '@/modules/opportunity_atlas/routes';
import PlaygroundRoutes from '@/modules/playground/routes';
import PriorArtRoutes from '@/modules/prior_art/routes';
import SenseEngineRoutes from '@/modules/sense_engine/routes';
import { getPreference, setPreference } from '@/utils/preferencesManager';
import VueRouter from 'vue-router';
import leaveGuard from '../directive/leaveGuard/leaveGuard';
import { API_HOST } from '../environment';
import AuthRoutes from '../modules/auth/routes';
import CanvasRoutes from '../modules/canvas/routes';
import DashboardRoutes from '../modules/dashboard/routes';
import InventionConceptRoutes from '../modules/invention_concepts/routes';
import ProfileRoutes from '../modules/profile/routes';
import ProjectsRoutes from '../modules/projects/routes';
import { projectsStore } from '../modules/projects/store';
import StandardsRoutes from '../modules/standards/routes';
import { reload } from '../plugins/browser';
import { appStore } from '../store/modules/app';
import { authStore } from '../store/modules/auth';
import { isValidUUID } from '../utils/validations';
const routes = [].concat(AuthRoutes, ProjectsRoutes, DashboardRoutes, StandardsRoutes, InventionConceptRoutes, ProfileRoutes, CanvasRoutes, OpportunityAtlasRoutes, SenseEngineRoutes, PriorArtRoutes, AgentRoutes, process.env.NODE_ENV === 'development' ? PlaygroundRoutes : []);
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
/**
 * Function called before each routing
 */
export async function beforeEach(to, from, next) {
    // Needed for SSO login route when backend is on different domain
    if (to.fullPath.startsWith('/api/')) {
        window.location.assign(API_HOST + to.fullPath);
        return;
    }
    /**
     * Handle redirection to login page if not authenticated.
     * Also handle redirection from login page to home page if authenticated.
     * The function is exported for easy unit testing access.
     */
    const isLoginRoute = to.matched.some(record => record.meta.isLoginRoute);
    if (isLoginRoute) {
        return next();
    }
    // If the route contain the project_id in param, we need to set it
    const requiredProjectRoute = Object.hasOwn(to.params, 'project_id');
    // Set table or atlas view in project depending of the preference before navigating from explore-ideas (project create)
    if ((from?.name === 'explore-ideas' || from?.name === 'explore-ideas-atlas') &&
        from?.params.project_id &&
        to?.name !== 'explore-ideas' &&
        to?.name !== 'explore-ideas-atlas') {
        await setPreference('PROJECT_TRIGGERS_FILTERS', { tableAtlasToggle: from.name === 'explore-ideas-atlas' ? 'atlas' : 'table' }, { projectId: from.params.project_id });
    }
    if (requiredProjectRoute) {
        const projectParamId = to.params.project_id;
        if (!isValidUUID(projectParamId)) {
            // The param is not a valid uuid, go to dashboard
            return next({
                name: 'dashboard',
                replace: true
            });
        }
        const auth = authStore();
        if (!auth.isAuthenticated) {
            return next();
        }
        try {
            await projectsStore().getProjectAndSetAsCurrent(projectParamId);
        }
        catch (e) {
            // Failed to retrieve the project, go to dashboard
            return next({
                name: 'dashboard',
                replace: true
            });
        }
        let currentProject;
        try {
            // The current project should be either the on we set with params, or the one that is in
            // the localstorage
            currentProject = await projectsStore().getCurrent();
        }
        catch (e) {
            // If we fail to retrieve the current project (when fetching, for example if the project
            // has been deleted), go back to dashboard
            return next({
                name: 'dashboard',
                replace: true
            });
        }
        if (!currentProject) {
            // If the project has not been set and the route required a project, go to dashboard
            return next({
                name: 'dashboard',
                replace: true
            });
        }
        // Set table or atlas view in project create depending of the preference
        if (from?.name !== 'explore-ideas' &&
            from?.name !== 'explore-ideas-atlas') {
            const preference = await getPreference('PROJECT_TRIGGERS_FILTERS', { projectId: projectParamId });
            if (preference?.tableAtlasToggle === 'atlas' && to.name === 'explore-ideas') {
                return next({
                    path: `/project/${projectParamId}/explore-ideas/atlas`,
                    replace: true
                });
            }
            else if (preference?.tableAtlasToggle === 'table' && to.name === 'explore-ideas-atlas') {
                // if 'table' is saved in preferences but current path is to 'atlas' - change it to 'table'
                return next({
                    path: `/project/${projectParamId}/explore-ideas/table`,
                    replace: true
                });
            }
        }
    }
    return next();
}
export async function onError(error) {
    if (error.name === 'ChunkLoadError') {
        const lastChunkFailure = localStorage.getItem('lastChunkFailure');
        if (lastChunkFailure !== error.message) {
            console.log('Chunk failure: reloading');
            localStorage.setItem('lastChunkFailure', error.message);
            reload();
        }
        else {
            console.log('Chunk failure: already seen - not reloading');
        }
    }
}
// Display confirmation dialog before leaving a page if there are unsaved changes on the page
export async function saveChangesGuard(to, from, next) {
    const app = appStore();
    if (app.hasPendingChanges) {
        const answer = window.confirm('You have unsaved changes.\n\n' +
            'Are you sure you want to leave this page?');
        if (!answer)
            return next(false);
        // Reset the pending changes flag because the user confirmed the navigation.
        else
            leaveGuard.setPendingChangesFlag(false);
    }
    next();
}
router.beforeEach((to, from, next) => beforeEach(to, from, next));
router.beforeEach((to, from, next) => saveChangesGuard(to, from, next));
router.onError(onError);
export default router;
