export default [
    {
        path: '/profile-settings',
        name: 'profile-settings',
        meta: {
            title: 'Settings'
        },
        component: () => import(/* webpackChunkName: "profile" */ '@/modules/profile/views/ProfileSettings.vue')
    },
    {
        path: '/next/profile-settings',
        name: 'next-profile-settings',
        meta: {
            title: 'Settings',
            nextRoute: true
        },
        component: () => import(/* webpackChunkName: "profile" */ '@/modules/profile/views/ProfileSettings.vue')
    }
];
