import * as d3 from 'd3';
export const backendZoomLevel = 5;
export const baseZoomLevel = 6;
export const maxZoomLevel = 11;
export const topLevelAspects = new Set([
    'Archaeology',
    'Environmental science',
    'Mathematics',
    'Political science',
    'Psychology',
    'Business',
    'Geography',
    'Physics',
    'Philosophy',
    'History',
    'Economics',
    'Sociology',
    'Geology',
    'Art',
    'Chemistry',
    'Engineering',
    'Computer science',
    'Medicine',
    'Biology',
    'Materials science',
    'Artificial intelligence',
    'Internal medicine',
    'Archeology'
]);
const CLUSTER_SIZE_VALUES = [1, 3, 10, 30, null];
export const CLUSTER_SIZE_CHOICES = ['1', '3', '10', '30', '100+'];
export const getClusterSizeValue = (index) => CLUSTER_SIZE_VALUES[index];
export const DATE_CHOICES = ['All', '5y', '1y', '6m', '2w', 'Now', 'Future'];
export const svgWidth = 1024;
export const starPath = d3.symbol().type(d3.symbolStar).size(30000);
export const selectedStarPath = d3.symbol().type(d3.symbolStar).size(150000);
export const baseRhombusShape = '-125,0 0,-125 125,0 0,125';
export const selectedRhombusShape = '-500,0 0,-500 500,0 0,500';
export const emptySelection = Object.freeze({
    type: '',
    target: undefined
});
export const DEFAULT_ZOOM_AREA = {
    minX: 0,
    maxX: 1,
    minY: 0,
    maxY: 1,
    zoomLevel: 1
};
export const ITEMS_PER_PAGE = 10;
export const CUT_OFF_INDEX = 4;
export const USED_ICON_SIZE = 22;
export const USED_ICON_FOCUSED_SIZE = 34;
export const FOCUSABLE_TYPES_WITH_CONNECTED_EFS = ['problem', 'ability', 'tracked'];
export const annotationsMaxScale = 15;
export const FLAG_ICON_SIZE = 18;
export const FLAG_ICON_FOCUSED_SIZE = 28;
// for heatmap
export const DEFAULT_TILE_COLOR = 'rgba(64, 194, 238, 0.1)';
export const TILE_DIMENSION = 256;
export const TILE_PADDING = 14;
// for heatmap changes
export const NO_CHANGE_COLORS = ['#808080', '#808080', '#808080'];
export const CHANGE_DATA_COLORS = ['#8B4513', '#D2B48C', '#F5DEB3', '#FFFFD4', '#98FB98', '#009900', '#006400'];
export const CHANGE_DATA_DOMAIN = [-1, -0.5, -0.1, 0, 0.1, 0.5, 1];
export const TIME_SLIDER_OLDEST_DATE = '2014-01-01';
