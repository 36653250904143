export const readFileAsDataURL = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            if (event.target?.result) {
                resolve(event.target.result.toString());
            }
            else {
                reject(new Error('Failed to read file.'));
            }
        };
        reader.onerror = (event) => {
            if (event.target?.error instanceof Error) {
                reject(event.target.error);
            }
            else {
                reject(new Error(reader.error?.toString() ?? 'Failed to read file.'));
            }
        };
        reader.readAsDataURL(file);
    });
};
export const getImageSize = (source) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve({ width: image.width, height: image.height });
        image.onerror = reject;
        image.src = source;
    });
};
export const getFileNameFromFileUrl = (document) => {
    return document.split('?')[0].split('/').pop();
};
