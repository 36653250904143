import { useFlagsStore } from '@/store/modules/flags';
import { defineStore, storeToRefs } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
export const useExploreTriggersStore = defineStore('explore-triggers-store', () => {
    const router = useRouter();
    const route = useRoute();
    const flagsStore = useFlagsStore();
    const { OPPORTUNITY_ATLAS_ENABLED } = storeToRefs(flagsStore);
    const oppAtlasActiveView = computed(() => route.name === 'explore-ideas-atlas' && OPPORTUNITY_ATLAS_ENABLED.value);
    // Navigate to the selected view - table or atlas
    const handleTableAtlasToggleChange = async (value) => {
        const routeLayout = route?.meta?.nextRoute ? 'next-' : '';
        router.push({
            name: value === 'table' ? `${routeLayout}explore-ideas` : `${routeLayout}explore-ideas-atlas`
        }).catch(() => { });
        // catch prevents throwing error when navigating to the same route
        // will need to find a better way to handle this globally
    };
    const state = ref({
        oppAtlasActiveView
    });
    const actions = reactive({
        handleTableAtlasToggleChange
    });
    return { state, actions };
});
