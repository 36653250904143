import { render, staticRenderFns } from "./AgentInput.vue?vue&type=template&id=2baa04d8&scoped=true&lang=pug"
import script from "./AgentInput.vue?vue&type=script&lang=ts"
export * from "./AgentInput.vue?vue&type=script&lang=ts"
import style0 from "./AgentInput.vue?vue&type=style&index=0&id=2baa04d8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2baa04d8",
  null
  
)

export default component.exports