export default [
  {
    path: '/next/sense-engine',
    name: 'next-sense-engine',
    meta: {
      title: 'Sense Engine',
      nextRoute: true
    },
    component: () => import(/* webpackChunkName: "contextsenseengine" */ '@/modules/sense_engine/views/SenseEngine')
  }
]
