// query params
// Scatter & Heatmap & Annotations - same query params
export var FeatureMiningQueryAction;
(function (FeatureMiningQueryAction) {
    // eslint-disable-next-line no-unused-vars
    FeatureMiningQueryAction["HYBRID"] = "HYBRID";
    // eslint-disable-next-line no-unused-vars
    FeatureMiningQueryAction["ANN"] = "ANN";
    // eslint-disable-next-line no-unused-vars
    FeatureMiningQueryAction["MATCH_PHRASE"] = "MATCH_PHRASE";
    // eslint-disable-next-line no-unused-vars
    FeatureMiningQueryAction["MORE_LIKE_THIS"] = "MORE_LIKE_THIS";
    // eslint-disable-next-line no-unused-vars
    FeatureMiningQueryAction["SEMANTIC"] = "semantic";
    // eslint-disable-next-line no-unused-vars
    FeatureMiningQueryAction["BM25"] = "BM25";
})(FeatureMiningQueryAction || (FeatureMiningQueryAction = {}));
