export default [{
        path: '/standards/standards',
        name: 'standards',
        meta: {
            title: 'Explore Standards',
            showCanvasPopover: true
        },
        component: () => import(/* webpackChunkName: "standards" */ '@/modules/standards/views/ExploreStandards')
    }, {
        path: '/standards/track-inventions',
        name: 'track-inventions',
        meta: {
            title: 'Track Inventions',
            showCanvasPopover: true
        },
        component: () => import(/* webpackChunkName: "standards" */ '@/modules/track_inventions/views/TrackInventions.vue')
    }, {
        path: '/next/standards',
        name: 'next-standards',
        meta: {
            title: 'Standards',
            nextRoute: true
        },
        component: () => import(/* webpackChunkName: "standards" */ '@/modules/standards/views/ExploreStandards')
    }
];
