import { formatStandardizationScore } from '@/modules/standards/utils/standardizationScore';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import flatten from 'lodash/flatten';
import fromPairs from 'lodash/fromPairs';
import isNumber from 'lodash/isNumber';
import omit from 'lodash/omit';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router/composables';
import { autocompleteTriggerHunting, bookmarkedAutocomplete, bookmarkTrigger, deleteBookmarkedTrigger, getBookmarkedTriggers, triggersSearch } from '../api/triggers';
import { ideaCreationSpaceStore } from '../modules/candidate_ideas/store/ideaCreationSpace';
import { projectsStore } from '../modules/projects/store';
import { DOCUMENTS_TYPES, INVENTIVENESS_SCORES } from '../modules/projects/utils/constants';
import { useStandardsStatisticsStore } from '../modules/standards/store/standardsStatistics';
import { ANN_SEARCH_ACTION, EXACT_SEARCH_ACTION, HYBRID_SEARCH_ACTION, KEYWORD_SEARCH_ACTION, SEMANTIC_SEARCH_ACTION, STANDARDS_FILTERS, STANDARDS_SEARCH_FILTERS } from '../modules/triggers/constants';
import { useTrackedTriggersStore } from '../modules/triggers/store/trackedTriggers';
import { turnTriggerIntoTracked } from '../modules/triggers/utils/typeConversion';
import store from '../store';
import { useFlagsStore } from '../store/modules/flags';
import { useAsyncState } from './asyncState';
import { makeAutocompleteBase } from './autocompleteBase';
import { getPreference, setPreference } from './preferencesManager';
import { fuzzySearchSync } from './search';
import { ensureUrlHasProtocol } from './strings';
export const SORT_COLUMNS = {
    title: 'title',
    trigger_date: 'date',
    standards_status: 'standards_metadata.status',
    standards_group: 'standards_metadata.group',
    standards_meeting: 'standards_metadata.meeting',
    tdoc_type: 'standards_metadata.doc_type',
    study_work_item: 'standards_metadata.study_work_item',
    standardization_score: 'standardization_score',
    standards_agenda_item: 'standards_metadata.agenda_item'
};
export const KEYWORD_SEARCH_FIELDS = {
    Any: undefined,
    Title: ['title'],
    'Document ID': ['standards_metadata.tdoc_id'],
    Content: ['content'],
    Claims: ['claims'],
    'Essential Features': ['essential_features.text']
};
export function setDefaultSearchParams(query, limit = 10, isStandardsModule = false) {
    return {
        typeFilter: 'ALL',
        trackedTriggerSearch: '',
        bookmarkedTriggersSearch: '',
        query: query ? [query] : [],
        search_type: SEMANTIC_SEARCH_ACTION,
        dateRanges: [dayjs().subtract(1, 'year').format('YYYY-MM-DD'), dayjs().add(1, 'year').format('YYYY-MM-DD')],
        inventivenessRanges: [0, INVENTIVENESS_SCORES.length - 1],
        offset: 0,
        types: [],
        limit,
        vectorToRank: 'document',
        fieldsToFilter: 'Any',
        assignees: [],
        scoping_aspects: [],
        standards_statuses: [],
        standards_topics: [],
        standards_doc_types: [],
        standards_releases: [],
        standards_meetings: [],
        standards_sources: [],
        standards_groups: [],
        standards_agenda_items: [],
        standards_study_work_items: [],
        standardizationScoreRanges: [0, 1],
        sort_field: '',
        sort_order: 'desc',
        bookmarked: false,
        must_not_match: [],
        includeProcessingDocuments: isStandardsModule,
        aspects_combination_type: 'OR'
    };
}
export function extractStandardsForTT(trigger) {
    return {
        standards_for: trigger.standards_metadata?.for,
        standards_group: trigger.standards_metadata?.group,
        standards_meeting: trigger.standards_metadata?.meeting,
        standards_release: trigger.standards_metadata?.release,
        standards_sources: trigger.standards_metadata?.sources,
        standards_status: trigger.standards_metadata?.status,
        standards_agenda_item: trigger.standards_metadata?.agenda_item,
        standards_revised_to: trigger.standards_metadata?.revised_to,
        standards_revision_of: trigger.standards_metadata?.revision_of,
        topics: trigger.topics ?? [],
        tdoc_type: trigger.standards_metadata?.doc_type,
        tdoc_id: trigger.standards_metadata?.tdoc_id,
        standardization_score: trigger.standardization_score,
        study_work_item: trigger.standards_metadata?.study_work_item
    };
}
const AUTOCOMPLETE_FILTER_VALUES = {
    'standards_metadata.agenda_item': 'standards_agenda_items',
    'standards_metadata.meeting': 'standards_meetings',
    topics: 'standards_topics',
    'standards_metadata.sources': 'standards_sources',
    'standards_metadata.group': 'standards_groups',
    'standards_metadata.release': 'standards_releases',
    'standards_metadata.status': 'standards_statuses',
    'standards_metadata.doc_type': 'standards_doc_types',
    'standards_metadata.study_work_item': 'standards_study_work_items',
    assignee: 'assignees'
};
export const FILTER_PILLS = {
    standards_statuses: 'Status',
    standards_topics: 'Topics',
    standards_doc_types: 'Document Types',
    standards_releases: 'Releases',
    standards_meetings: 'Meetings',
    standards_sources: 'Sources',
    standards_groups: 'Groups',
    standards_study_work_items: 'Study Work Items',
    standards_agenda_items: 'Agenda Items',
    assignees: 'Assignees',
    aspects: 'Topics'
};
export function makeTriggerSearchStore(config) {
    return () => {
        const route = useRoute();
        // switching between `atlas view` and `table view`
        // only in project view - not for the standards module
        watch(() => route.name, async () => {
            if (!config.isStandardsModule && (route.name === 'explore-ideas' || route.name === 'explore-ideas-atlas')) {
                const isAtlasView = route.name === 'explore-ideas-atlas';
                if (isAtlasView && triggerSearchParams.value.typeFilter !== 'ALL') {
                    triggerSearchParams.value.search_type = KEYWORD_SEARCH_ACTION;
                }
                else if (!isAtlasView) {
                    await triggers.execute();
                }
            }
        });
        const shouldFetchTableTriggers = computed(() => route.name !== 'explore-ideas-atlas');
        const standardsStatisticsStore = useStandardsStatisticsStore();
        const triggers = useAsyncState(async () => {
            let response;
            if (triggerSearchParams.value.bookmarked) {
                response = await getBookmarkedTriggers(apiParameters.value);
                return {
                    triggers: response.data.results.map((t) => t.document),
                    count: response.data.count,
                    countIsApproximate: false
                };
            }
            else {
                response = await triggersSearch(apiParameters.value);
                return {
                    triggers: response.data.triggers,
                    count: response.data.count,
                    countIsApproximate: response.data.count_is_approximate
                };
            }
        }, {
            triggers: [],
            count: undefined,
            countIsApproximate: false
        }, {
            onError: () => {
                store.commit('snackbar/show', {
                    color: 'error',
                    message: 'Error while trying to fetch triggers'
                });
            },
            debounceMs: 50
        });
        const triggerSearchParams = ref(setDefaultSearchParams('', 10, config.isStandardsModule));
        const triggerSearchQueries = ref([]);
        const showMoreFilters = ref(false);
        const include3GPPLiterature = ref(config.include3GPPLiterature);
        const isStandardsModule = ref(config.isStandardsModule);
        const processingDocsStateOn3GPPChange = ref(false);
        // We need to have 2 different offsets for the 2 views because when the user is in the main view and switches to
        // the standards view, and changes the page ( the offset ) and then the user switches back to the main view, the offset
        // should be the same as it was before switching to the standards view and vice versa.
        // This way they won't lose track of the page they were on in each view.
        const mainViewOffset = ref(0);
        const standardsViewOffset = ref(0);
        const shouldShowStandardizationScorePill = computed(() => (triggerSearchParams.value.standardizationScoreRanges[0] !== 0 ||
            triggerSearchParams.value.standardizationScoreRanges[1] !== 1) && include3GPPLiterature.value);
        const shouldShowInventivenessScorePill = computed(() => (triggerSearchParams.value.inventivenessRanges[0] !== 0 ||
            triggerSearchParams.value.inventivenessRanges[1] !== INVENTIVENESS_SCORES.length - 1) && !include3GPPLiterature.value);
        const shouldShowDocumentTypesPill = computed(() => triggerSearchParams.value.types.length !== 0 && triggerSearchParams.value.types.length !== 4 && !include3GPPLiterature.value);
        const detailedSearchParams = computed(() => {
            const params = {};
            if (triggerSearchParams.value.standards_statuses.length > 0)
                params.standards_statuses = triggerSearchParams.value.standards_statuses;
            if (triggerSearchParams.value.standards_topics.length > 0)
                params.standards_topics = triggerSearchParams.value.standards_topics;
            if (triggerSearchParams.value.standards_doc_types.length > 0)
                params.standards_doc_types = triggerSearchParams.value.standards_doc_types;
            if (triggerSearchParams.value.standards_releases.length > 0)
                params.standards_releases = triggerSearchParams.value.standards_releases;
            if (triggerSearchParams.value.standards_meetings.length > 0)
                params.standards_meetings = triggerSearchParams.value.standards_meetings;
            if (triggerSearchParams.value.standards_sources.length > 0)
                params.standards_sources = triggerSearchParams.value.standards_sources;
            if (triggerSearchParams.value.standards_groups.length > 0)
                params.standards_groups = triggerSearchParams.value.standards_groups;
            if (triggerSearchParams.value.standards_study_work_items.length > 0)
                params.standards_study_work_items = triggerSearchParams.value.standards_study_work_items;
            if (triggerSearchParams.value.standards_agenda_items.length > 0)
                params.standards_agenda_items = triggerSearchParams.value.standards_agenda_items;
            return params;
        });
        const isAssigneeFilterActive = computed(() => triggerSearchParams.value.assignees.length > 0 && !include3GPPLiterature.value);
        const standardsDocTypeSelected = computed(() => triggerSearchParams.value.types.some(docs => docs.includes(DOCUMENTS_TYPES.STANDARD)));
        const saveFiltersInPreferences = (projectUUID) => {
            const searchParamsToSave = omit(triggerSearchParams.value, ['must_not_match']);
            setPreference('PROJECT_TRIGGERS_FILTERS', {
                search_filters: searchParamsToSave,
                include3GPPLiterature: include3GPPLiterature.value
            }, { projectId: projectUUID });
        };
        const setDocTypes = () => {
            if (include3GPPLiterature.value)
                triggerSearchParams.value.types = [[DOCUMENTS_TYPES.STANDARD]];
            else
                triggerSearchParams.value.types = [[DOCUMENTS_TYPES.JOURNAL], [DOCUMENTS_TYPES.MEDIA], [DOCUMENTS_TYPES.PATENT]];
        };
        const initialize = async () => {
            let loadedFromPreferences = false;
            standardsStatisticsStore.standardsChartView = false;
            const project = projectsStore().currentProject;
            if (project) {
                const preference = await getPreference('PROJECT_TRIGGERS_FILTERS', { projectId: project.uuid });
                if (preference?.search_filters) {
                    loadedFromPreferences = true;
                    Object.assign(triggerSearchParams.value, preference.search_filters);
                    include3GPPLiterature.value = preference.include3GPPLiterature;
                }
                else {
                    triggerSearchParams.value = setDefaultSearchParams(project.topic, triggerSearchParams.value.limit, isStandardsModule.value);
                }
            }
            if (!loadedFromPreferences)
                setDocTypes();
            await triggers.execute();
        };
        const matchByFieldOptions = computed(() => {
            const options = Object.keys(KEYWORD_SEARCH_FIELDS);
            if (!useFlagsStore().STANDARDS_ACCESS) {
                options.splice(options.indexOf('Document ID'), 1);
            }
            return options;
        });
        const get3GPPAPIParameters = (parameters) => {
            let sortField;
            let levelOneAggregator;
            let levelTwoAggregator;
            if (triggerSearchParams.value.sort_field) {
                sortField = triggerSearchParams.value.sort_field;
            }
            else if (!triggerSearchParams.value.query) {
                sortField = 'date';
            }
            if (standardsStatisticsStore.standardsChartView) {
                // @ts-ignore
                levelOneAggregator = STANDARDS_FILTERS[standardsStatisticsStore.levelOneDimension.split(' ').join('_')];
                // @ts-ignore
                levelTwoAggregator = standardsStatisticsStore.levelTwoDimension ? STANDARDS_FILTERS[standardsStatisticsStore.levelTwoDimension.split(' ').join('_')] : '';
            }
            const standardizationScore = formatStandardizationScore(triggerSearchParams.value.standardizationScoreRanges);
            parameters = {
                ...parameters,
                ...detailedSearchParams.value,
                standardization_score_from: standardizationScore[0],
                standardization_score_to: standardizationScore[1],
                sort_field: sortField,
                sort_direction: sortField ? triggerSearchParams.value.sort_order : undefined,
                level_1_aggregator: levelOneAggregator,
                level_2_aggregator: levelTwoAggregator
            };
            if (parameters.standardization_score_from === 0)
                parameters.standardization_score_from = undefined;
            if (parameters.standardization_score_to === 1)
                parameters.standardization_score_to = undefined;
            return parameters;
        };
        const apiParameters = computed(() => {
            let action = '';
            if ([KEYWORD_SEARCH_ACTION, EXACT_SEARCH_ACTION].includes(triggerSearchParams.value.search_type)) {
                action = triggerSearchParams.value.search_type;
            }
            else if (include3GPPLiterature.value) {
                action = ANN_SEARCH_ACTION;
            }
            else {
                action = HYBRID_SEARCH_ACTION;
            }
            let parameters = {
                project_id: undefined,
                query: triggerSearchParams.value.query,
                date_from: triggerSearchParams.value.dateRanges[0],
                date_to: triggerSearchParams.value.dateRanges[1],
                types: flatten(triggerSearchParams.value.types),
                offset: triggerSearchParams.value.offset,
                num_results: triggerSearchParams.value.limit,
                vector_to_match: undefined,
                fields_to_match: undefined,
                action,
                sort_field: undefined,
                sort_direction: undefined,
                bookmarked: triggerSearchParams.value.bookmarked,
                scoping_aspects: triggerSearchParams.value.scoping_aspects,
                aspects_combination_type: triggerSearchParams.value.scoping_aspects.length > 1 ? triggerSearchParams.value.aspects_combination_type : undefined,
                limit: undefined,
                is_standards_module: isStandardsModule.value,
                must_not_match: triggerSearchParams.value.must_not_match,
                exists_filter: triggerSearchParams.value.includeProcessingDocuments ? [] : ['essential_features']
            };
            if (parameters.bookmarked) {
                parameters = {
                    ...parameters,
                    query: [triggerSearchParams.value.bookmarkedTriggersSearch],
                    limit: parameters.num_results
                };
            }
            if (!isStandardsModule.value && projectsStore().currentProject)
                parameters.project_id = projectsStore().currentProjectId;
            if (!include3GPPLiterature.value) {
                parameters = {
                    ...parameters,
                    // @ts-ignore
                    assignees: triggerSearchParams.value.assignees?.length ? triggerSearchParams.value.assignees : undefined,
                    inventiveness_score_from: INVENTIVENESS_SCORES[triggerSearchParams.value.inventivenessRanges[0]],
                    inventiveness_score_to: INVENTIVENESS_SCORES[triggerSearchParams.value.inventivenessRanges[1]]
                };
            }
            else {
                parameters = get3GPPAPIParameters(parameters);
            }
            if ([KEYWORD_SEARCH_ACTION, EXACT_SEARCH_ACTION].includes(parameters.action)) {
                parameters.fields_to_match = KEYWORD_SEARCH_FIELDS[triggerSearchParams.value.fieldsToFilter];
            }
            else {
                parameters.vector_to_match = triggerSearchParams.value.vectorToRank;
            }
            return parameters;
        });
        const debouncedFetchTriggersList = debounce(triggers.execute, 500);
        const lastSearchParams = ref(triggerSearchParams.value);
        watch(triggerSearchParams, async (value) => {
            // if something changed that isn't offset, reset offset to 0
            if (value.offset === lastSearchParams.value.offset) {
                if (lastSearchParams.value.offset !== 0)
                    triggerSearchParams.value.offset = 0;
                if (shouldFetchTableTriggers.value)
                    triggers.reset();
            }
            lastSearchParams.value = cloneDeep(value);
            if ((isStandardsModule.value || triggerSearchParams.value.typeFilter === 'ALL') && shouldFetchTableTriggers.value) {
                await debouncedFetchTriggersList();
            }
            if (standardsStatisticsStore.standardsChartView) {
                if (!isStandardsModule.value && triggerSearchParams.value.typeFilter !== 'ALL' && !include3GPPLiterature.value) {
                    return;
                }
                await standardsStatisticsStore.getStandardsStatistics();
            }
        }, { deep: true });
        const handleStandardsDocTypeClick = () => {
            const newValStandardsSelected = !standardsDocTypeSelected.value;
            const shouldSetDocsStateBefore3GPP = !triggerSearchParams.value.includeProcessingDocuments;
            if (shouldSetDocsStateBefore3GPP && newValStandardsSelected) {
                processingDocsStateOn3GPPChange.value = triggerSearchParams.value.includeProcessingDocuments;
                triggerSearchParams.value.includeProcessingDocuments = true;
            }
            else if (!newValStandardsSelected) {
                triggerSearchParams.value.includeProcessingDocuments = processingDocsStateOn3GPPChange.value;
            }
        };
        watch(() => triggerSearchParams.value.includeProcessingDocuments, () => {
            if (isStandardsModule.value && !include3GPPLiterature.value && !standardsDocTypeSelected.value) {
                processingDocsStateOn3GPPChange.value = triggerSearchParams.value.includeProcessingDocuments;
            }
        });
        const trackTriggerIdByTrigger = computed(() => {
            return fromPairs(useTrackedTriggersStore().trackedTriggers.map((tt) => [tt.url, tt]));
        });
        const trackedTriggersList = computed(() => {
            return fuzzySearchSync(useTrackedTriggersStore().trackedTriggers, triggerSearchParams.value.trackedTriggerSearch, ['title', 'content']);
        });
        const usedTriggersList = computed(() => {
            const triggers = ideaCreationSpaceStore().triggers.map((t) => ({
                url: t.url,
                title: t.doc_title,
                assignee: t.assignee,
                doc_type: t.doc_type,
                location_x: t.location_x,
                location_y: t.location_y,
                essential_features: [{
                        content: t.text,
                        type: t.trigger_type,
                        tracked_trigger: null,
                        id: -1,
                        created_by: null
                    }]
            }));
            return fuzzySearchSync(triggers, triggerSearchParams.value.trackedTriggerSearch, ['title', 'content']);
        });
        const triggersCount = computed(() => {
            if (!isStandardsModule.value && triggerSearchParams.value.typeFilter === 'USED') {
                return usedTriggersList.value.length;
            }
            else if (!isStandardsModule.value && triggerSearchParams.value.typeFilter === 'TRACKED') {
                return trackedTriggersList.value.length;
            }
            else if (triggers.state.value.count === undefined) {
                return -1;
            }
            else if (apiParameters.value.action === KEYWORD_SEARCH_ACTION || apiParameters.value.action === EXACT_SEARCH_ACTION) {
                return triggers.state.value.count;
            }
            else {
                return Math.min(triggers.state.value.count, 1000);
            }
        });
        const triggersCountIsApproximate = computed(() => {
            if (!isStandardsModule.value && (triggerSearchParams.value.typeFilter === 'USED' || triggerSearchParams.value.typeFilter === 'TRACKED')) {
                return false;
            }
            return triggers.state.value.countIsApproximate || false;
        });
        const triggersList = computed(() => {
            if (!isStandardsModule.value) {
                if (triggerSearchParams.value.typeFilter === 'TRACKED') {
                    return trackedTriggersList.value.slice(triggerSearchParams.value.offset, triggerSearchParams.value.offset + triggerSearchParams.value.limit);
                }
                if (triggerSearchParams.value.typeFilter === 'USED') {
                    return usedTriggersList.value.slice(triggerSearchParams.value.offset, triggerSearchParams.value.offset + triggerSearchParams.value.limit);
                }
            }
            return triggers.state.value.triggers.map((trigger) => {
                const tt = trackTriggerIdByTrigger.value[ensureUrlHasProtocol(trigger.url)];
                if (tt) {
                    const extractedData = extractStandardsForTT(trigger);
                    return { ...tt, ...extractedData, assignee: trigger.assignee };
                }
                // @ts-ignore
                return turnTriggerIntoTracked(trigger, projectsStore().currentProjectId);
            });
        });
        const handleSort = (column) => {
            if (triggerSearchParams.value.sort_order === 'desc' && triggerSearchParams.value.sort_field === column) {
                triggerSearchParams.value.sort_field = '';
                triggerSearchParams.value.sort_order = 'asc';
            }
            else if (triggerSearchParams.value.sort_field === column) {
                triggerSearchParams.value.sort_order = 'desc';
            }
            else {
                triggerSearchParams.value.sort_field = column;
                triggerSearchParams.value.sort_order = 'asc';
            }
        };
        const makeSortOrderIcon = (field) => computed(() => {
            if (triggerSearchParams.value.sort_order === 'asc' && triggerSearchParams.value.sort_field === field)
                return 'mdi-sort-alphabetical-ascending';
            return 'mdi-sort-alphabetical-descending';
        });
        const makeNumericSortOrderIcon = (field) => computed(() => {
            if (triggerSearchParams.value.sort_order === 'asc' && triggerSearchParams.value.sort_field === field)
                return 'mdi-sort-numeric-ascending';
            return 'mdi-sort-numeric-descending';
        });
        const dateSortOrder = computed(() => {
            if (triggerSearchParams.value.sort_order === 'asc' && triggerSearchParams.value.sort_field === 'date')
                return 'mdi-sort-calendar-ascending';
            return 'mdi-sort-calendar-descending';
        });
        const searchDocById = (standardId) => {
            triggerSearchParams.value = setDefaultSearchParams('', triggerSearchParams.value.limit, isStandardsModule.value);
            // this is before the first standards document.
            triggerSearchParams.value.dateRanges[0] = '1996-01-01';
            triggerSearchParams.value.search_type = EXACT_SEARCH_ACTION;
            triggerSearchParams.value.fieldsToFilter = 'Document ID';
            triggerSearchParams.value.types = [[DOCUMENTS_TYPES.STANDARD]];
            triggerSearchParams.value.query = [standardId];
            triggers.execute();
        };
        const toggleBookmarkTrigger = async (trigger) => {
            if (!isStandardsModule.value)
                return;
            const foundTrigger = triggers.state.value.triggers.find((t) => t.url === trigger.url);
            if (!foundTrigger)
                return;
            try {
                if (foundTrigger.bookmark_id) {
                    await deleteBookmarkedTrigger(foundTrigger.bookmark_id);
                    foundTrigger.bookmark_id = null;
                    if (triggerSearchParams.value.bookmarked) {
                        // we are mutating to avoid a useless refresh, since we know what the output state is
                        triggers.state.value.triggers = triggers.state.value.triggers.filter((t) => t.url !== trigger.url);
                        if (isNumber(triggers.state.value.count)) {
                            triggers.state.value.count = triggers.state.value.count - 1;
                        }
                    }
                    return;
                }
                const bt = (await bookmarkTrigger({ url: trigger.url })).data;
                foundTrigger.bookmark_id = bt.uuid;
            }
            catch (e) {
                store.commit('snackbar/show', {
                    color: 'error',
                    message: "Couldn't toggle bookmark. Please try again."
                });
            }
        };
        const clearFilters = () => {
            triggerSearchParams.value = setDefaultSearchParams(triggerSearchParams.value.query[0], triggerSearchParams.value.limit, isStandardsModule.value);
            if (include3GPPLiterature.value) {
                triggerSearchParams.value.types = [[DOCUMENTS_TYPES.STANDARD]];
                triggerSearchParams.value.search_type = KEYWORD_SEARCH_ACTION;
            }
        };
        watch(include3GPPLiterature, (value) => {
            if (!value) {
                triggerSearchParams.value.offset = mainViewOffset.value;
                standardsStatisticsStore.standardsChartView = false;
                if (!isStandardsModule.value)
                    triggerSearchParams.value.includeProcessingDocuments = processingDocsStateOn3GPPChange.value;
            }
            else {
                triggerSearchParams.value.search_type = KEYWORD_SEARCH_ACTION;
                triggerSearchParams.value.offset = standardsViewOffset.value;
                if (!triggerSearchParams.value.includeProcessingDocuments && !isStandardsModule.value) {
                    processingDocsStateOn3GPPChange.value = triggerSearchParams.value.includeProcessingDocuments;
                    triggerSearchParams.value.includeProcessingDocuments = true;
                }
            }
            setDocTypes();
        });
        function makeAutocomplete(autocompleteKey, filterValues) {
            return makeAutocompleteBase(async (text, apiParameters, signal) => {
                const apiCall = triggerSearchParams.value.bookmarked
                    ? bookmarkedAutocomplete({
                        ...apiParameters,
                        [AUTOCOMPLETE_FILTER_VALUES[autocompleteKey]]: [text],
                        field: AUTOCOMPLETE_FILTER_VALUES[autocompleteKey]
                    }, signal)
                    : autocompleteTriggerHunting(autocompleteKey, text, apiParameters, signal);
                return (await apiCall).data.results;
            }, filterValues, () => apiParameters.value);
        }
        const autocompleteSelectedFilters = computed(() => {
            let filterPills = Object.keys(FILTER_PILLS);
            if (include3GPPLiterature.value)
                filterPills = filterPills.filter(fp => fp !== 'assignees' && fp !== 'aspects');
            else
                filterPills = ['assignees'];
            return filterPills.filter(key => {
                // @ts-ignore
                return triggerSearchParams.value[key].length > 0;
            });
        });
        const removeFilters = (filter) => {
            if (filter === 'standardizationScoreRanges')
                triggerSearchParams.value[filter] = [0, 1];
            else if (filter === 'inventivenessRanges')
                triggerSearchParams.value[filter] = [0, INVENTIVENESS_SCORES.length - 1];
            // @ts-ignore
            else
                triggerSearchParams.value[filter] = [];
        };
        const updateFilter = (filter, values) => {
            // @ts-ignore
            triggerSearchParams.value[filter] = values;
        };
        const updateChartSearchParams = (params) => {
            if (params) {
                // @ts-ignore
                triggerSearchParams.value[STANDARDS_SEARCH_FILTERS[standardsStatisticsStore.levelOneDimension.replaceAll(' ', '_')]].push(params.name);
                if (standardsStatisticsStore.levelTwoDimension) {
                    // @ts-ignore
                    triggerSearchParams.value[STANDARDS_SEARCH_FILTERS[standardsStatisticsStore.levelTwoDimension.replaceAll(' ', '_')]].push(params.seriesName);
                }
            }
        };
        watch(isAssigneeFilterActive, (value) => {
            if (value) {
                triggerSearchParams.value.types = [[DOCUMENTS_TYPES.PATENT]];
            }
        });
        return {
            triggersList,
            fetchTriggersList: triggers.execute,
            fetchingTriggers: triggers.loading,
            triggerSearchParams,
            updateChartSearchParams,
            trackTriggerIdByTrigger,
            triggers: triggers.state.value,
            triggersCount,
            triggersCountIsApproximate,
            agendaItemsAutocomplete: makeAutocomplete('standards_metadata.agenda_item', () => triggerSearchParams.value.standards_agenda_items),
            meetingsAutocomplete: makeAutocomplete('standards_metadata.meeting', () => triggerSearchParams.value.standards_meetings),
            topicsAutocomplete: makeAutocomplete('topics', () => triggerSearchParams.value.standards_topics),
            sourcesAutocomplete: makeAutocomplete('standards_metadata.sources', () => triggerSearchParams.value.standards_sources),
            groupsAutocomplete: makeAutocomplete('standards_metadata.group', () => triggerSearchParams.value.standards_groups),
            releasesAutocomplete: makeAutocomplete('standards_metadata.release', () => triggerSearchParams.value.standards_releases),
            statusesAutocomplete: makeAutocomplete('standards_metadata.status', () => triggerSearchParams.value.standards_statuses),
            docTypesAutocomplete: makeAutocomplete('standards_metadata.doc_type', () => triggerSearchParams.value.standards_doc_types),
            studyWorkItemsAutocomplete: makeAutocomplete('standards_metadata.study_work_item', () => triggerSearchParams.value.standards_study_work_items),
            assigneesAutocomplete: makeAutocomplete('assignee', () => triggerSearchParams.value.assignees),
            handleSort,
            dateSortOrder,
            matchByFieldOptions,
            titleSortOrder: makeSortOrderIcon('title'),
            statusSortOrder: makeSortOrderIcon('standards_metadata.status'),
            meetingSortOrder: makeSortOrderIcon('standards_metadata.meeting'),
            typeSortOrder: makeSortOrderIcon('standards_metadata.doc_type'),
            scoreSortOrder: makeNumericSortOrderIcon('standardization_score'),
            agendaItemSortOrder: makeNumericSortOrderIcon('standards_metadata.agenda_item'),
            workItemSortOrder: makeSortOrderIcon('standards_metadata.study_work_item'),
            _apiParameters: apiParameters,
            detailedSearchParams,
            searchDocById,
            standardsViewOffset,
            triggerSearchQueries,
            showMoreFilters,
            include3GPPLiterature,
            mainViewOffset,
            autocompleteSelectedFilters,
            shouldShowStandardizationScorePill,
            shouldShowInventivenessScorePill,
            shouldShowDocumentTypesPill,
            isAssigneeFilterActive,
            standardsDocTypeSelected,
            updateFilter,
            removeFilters,
            initialize,
            clearFilters,
            handleStandardsDocTypeClick,
            toggleBookmarkTrigger,
            saveFiltersInPreferences,
            usedTriggersList
        };
    };
}
