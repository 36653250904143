import http from '../plugins/http';
const ENDPOINT = 'agent_prompts';
export async function getLanggraphRun(runId) {
    return await http.get(`agent_run_results/${runId}/`, {});
}
export async function getPrompts() {
    return await http.get(`${ENDPOINT}/`, {});
}
export async function getPrompt(promptId) {
    return await http.get(`${ENDPOINT}/${promptId}/`, {});
}
export async function patchPrompt(promptId, content) {
    return await http.patch(`${ENDPOINT}/${promptId}/`, { content });
}
export async function deletePrompt(promptId) {
    return await http.delete(`${ENDPOINT}/${promptId}/`);
}
export async function getGraphs() {
    return await http.get(`${ENDPOINT}/graphs/`, {});
}
export const createTask = () => http.post('/agenttasks');
export const listRuns = (task) => http.get('agent_run_results', { params: { thread: task } });
export const getRun = (id) => http.get(`agent_run_results/${id}/`);
