export var PROJECT_WORKFLOW_CHOICES;
(function (PROJECT_WORKFLOW_CHOICES) {
    // eslint-disable-next-line no-unused-vars
    PROJECT_WORKFLOW_CHOICES["NEW_INVENTION"] = "NEW_INVENTION";
    // eslint-disable-next-line no-unused-vars
    PROJECT_WORKFLOW_CHOICES["EVALUATE_AND_IMPROVE_EXISTING_IDEA"] = "EVALUATE_AND_IMPROVE_EXISTING_IDEA";
    // eslint-disable-next-line no-unused-vars
    PROJECT_WORKFLOW_CHOICES["CREATE_PATENT_WORKAROUND"] = "CREATE_PATENT_WORKAROUND";
    // eslint-disable-next-line no-unused-vars
    PROJECT_WORKFLOW_CHOICES["TRACK_COMPETITORS"] = "TRACK_COMPETITORS";
})(PROJECT_WORKFLOW_CHOICES || (PROJECT_WORKFLOW_CHOICES = {}));
