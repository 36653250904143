import { DocumentType } from '../utils/constants';
export function isRawEssentialFeature(ef) {
    return Object.hasOwn(ef, 'text');
}
export function isTrackedTrigger(trigger) {
    // @ts-ignore
    return Object.hasOwn(trigger, 'id');
}
export function triggerAsSimpleTrigger(trigger, essentialFeature) {
    if (!essentialFeature)
        return;
    return {
        text: essentialFeature.text,
        url: trigger.url,
        assignee: trigger.assignee,
        doc_type: getSimpleTriggerDocType(trigger.type),
        doc_title: trigger.title,
        trigger_type: essentialFeature.type.toUpperCase(),
        is_main_trigger: false,
        location_x: trigger.location_x,
        location_y: trigger.location_y
    };
}
export function connectedFeatureAsSimpleTrigger(ef) {
    return {
        text: ef.text,
        url: ef.url,
        assignee: '',
        doc_type: getSimpleTriggerDocType(ef.doc_type),
        doc_title: '',
        trigger_type: ef.type.toUpperCase(),
        location_y: ef.location_y,
        location_x: ef.location_x,
        is_main_trigger: false
    };
}
export function trackedTriggerAsSimpleTrigger(trigger, essentialFeature) {
    if (!essentialFeature)
        return;
    return {
        text: essentialFeature.content,
        url: trigger.url,
        assignee: '',
        doc_type: getSimpleTriggerDocType(trigger.doc_type),
        doc_title: trigger.title,
        trigger_type: (essentialFeature.type?.toUpperCase() ?? 'ABILITY'),
        is_main_trigger: false,
        location_x: trigger.location_x,
        location_y: trigger.location_y
    };
}
export function topFeatureAsSimpleTrigger(topFeature, triggerType) {
    return {
        text: topFeature.text,
        url: topFeature.url,
        assignee: topFeature.assignee,
        content: topFeature.doc_content.join(' '),
        doc_type: topFeature.doc_type.toUpperCase(),
        doc_title: topFeature.title,
        trigger_type: triggerType,
        is_main_trigger: false,
        location_x: topFeature.location_x,
        location_y: topFeature.location_y
    };
}
export function essentialFeaturesForTrackedTrigger(efs) {
    if (!efs)
        return [];
    return efs.map(ef => ({
        content: ef.text,
        type: ef.type.toUpperCase()
    }));
}
export function getSimpleTriggerDocType(docType) {
    if (docType === DocumentType.JOURNAL)
        return 'JOURNAL';
    if (docType === DocumentType.MEDIA)
        return 'MEDIA';
    if (docType === DocumentType.STANDARD)
        return 'STANDARD';
    return 'PATENT';
}
export function simpleTriggerAsTrigger(trigger) {
    return {
        assignee: trigger.assignee ?? '',
        bookmark_id: null,
        content: trigger.content ? [trigger.content] : [],
        essential_features: [{
                type: trigger.trigger_type.toLowerCase(),
                text: trigger.text,
                location_y: undefined,
                location_x: undefined
            }],
        standards_content_summary: '',
        title: trigger.doc_title,
        type: trigger.doc_type.toLowerCase(),
        url: trigger.url,
        patent_number: trigger.patent_number ?? '',
        location_x: trigger.location_x,
        location_y: trigger.location_y
    };
}
export var TRACK_INVENTION_USER_PERMISSIONS;
(function (TRACK_INVENTION_USER_PERMISSIONS) {
    // eslint-disable-next-line no-unused-vars
    TRACK_INVENTION_USER_PERMISSIONS["READ"] = "READ";
    // eslint-disable-next-line no-unused-vars
    TRACK_INVENTION_USER_PERMISSIONS["WRITE"] = "WRITE";
})(TRACK_INVENTION_USER_PERMISSIONS || (TRACK_INVENTION_USER_PERMISSIONS = {}));
export const TRACKED_INVENTIONS_SORT_COLUMNS = {
    search_name: 'search_name',
    owner: 'user_fullname',
    new_matches: 'matches_since_last_seen',
    all_matches: 'total_matches',
    created_at: 'date',
    tags: 'tags'
};
