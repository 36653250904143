import { dateToIsoDate } from '@/utils/constants';
import { PROJECT_WORKFLOW_CHOICES } from '@/interfaces/projects';
export const DATE_CHOICES = [
    'All',
    '5y',
    '2y',
    '1y',
    '6m',
    '2m',
    '2w',
    'Now',
    'Future'
];
export const INVENTIVENESS_SCORES = [0, 0.25, 0.5, 0.75, 1];
export const getFilterDate = (index, choicesArray = DATE_CHOICES) => {
    const today = new Date();
    const dateMapping = {
        All: () => new Date(0),
        '5y': () => today.setFullYear(today.getFullYear() - 5),
        '2y': () => today.setFullYear(today.getFullYear() - 2),
        '1y': () => today.setFullYear(today.getFullYear() - 1),
        '6m': () => today.setMonth(today.getMonth() - 6),
        '2m': () => today.setMonth(today.getMonth() - 2),
        '2w': () => today.setDate(today.getDate() - 14),
        Now: () => today,
        Future: () => today.setFullYear(2100)
    };
    const key = choicesArray[index];
    if (dateMapping[key])
        return dateToIsoDate(new Date(dateMapping[key]()));
};
export const DOCUMENTS_TYPES = {
    JOURNAL: 'journal',
    MEDIA: 'media',
    PATENT: 'patent',
    STANDARD: 'standard'
};
export const PROJECT_WORKFLOWS = [
    { name: 'New Invention', value: PROJECT_WORKFLOW_CHOICES.NEW_INVENTION },
    { name: 'Evaluate and improve existing idea', value: PROJECT_WORKFLOW_CHOICES.EVALUATE_AND_IMPROVE_EXISTING_IDEA },
    { name: 'Create patent workaround', value: PROJECT_WORKFLOW_CHOICES.CREATE_PATENT_WORKAROUND },
    { name: 'Track competitors', value: PROJECT_WORKFLOW_CHOICES.TRACK_COMPETITORS }
];
