import { TIME_SLIDER_OLDEST_DATE } from '@/modules/opportunity_atlas/utils/constants';
import dayjs from 'dayjs';
export const capDateToToday = (formattedEndDate) => {
    const today = dayjs();
    const endDate = dayjs(formattedEndDate);
    return endDate.isAfter(today) ? today : endDate;
};
export const capStartDateToSlider = (formattedStartDate) => {
    const startDate = dayjs(formattedStartDate);
    const oldestAllowedDate = dayjs(TIME_SLIDER_OLDEST_DATE);
    return startDate.isBefore(oldestAllowedDate) ? oldestAllowedDate : startDate;
};
export const capDateRangeToSlider = ([startDate, endDate]) => [capStartDateToSlider(startDate), capDateToToday(endDate)];
export const fromNow = (date) => dayjs(date).fromNow();
export const formatLocalDate = (date) => {
    if (!date)
        return '';
    return dayjs(date).format('LL');
};
