export default [
  {
    path: '/next/opportunity-atlas',
    name: 'next-opportunity-atlas',
    meta: {
      title: 'Opportunity Atlas',
      nextRoute: true
    },
    component: () => import(/* webpackChunkName: "contextoppatlas" */ '@/modules/opportunity_atlas/views/ContextOpportunityAtlas')
  }
]
