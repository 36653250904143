<template lang="pug">
  v-app(id="invention-studio")
    div
      div(v-if="configLoadingError")
        critical-error(errorMsg="The service is currently unavailable. Please check back shortly. If the problem persists, please contact our Technical Support team.")
      Login(v-else-if="!hasUser")
      template(v-else)

        v-navigation-drawer.navigation-drawer(
          app
          :mini-variant="mainMenuCollapsed"
          mobile-breakpoint=0
          floating
          dark
          :color="darkMode ? 'iprovaDark' : 'iprovaNextLight'"
          :class="currentTheme"
        )
          v-list.px-0.d-flex.flex-column.drawer-wrapper(dense nav)
            // Iprova Logo
            RouterLink.d-contents(:to="{ name: 'next' }")
              v-list-item.mt-n1(:class="mainMenuCollapsed ? 'logo-wrapper-collapsed' : 'logo-wrapper'")
                v-list-item-icon(v-if="mainMenuCollapsed")
                  span.iprova-icon-small.mt-n1(
                    :style="{backgroundImage: `url(${iprovaLogo.small_logo})`}"
                  )
                v-list-item-icon.expanded-menu-logo(v-else)
                  img.iprova-icon.ml-n3.mt-n1(
                    :src="iprovaLogo.large_logo"
                    alt="Invention Studio Logo"
                  )
                  span.software-version v7.2

            v-list-item.mt-2(exact :to="{ name: 'next' }")
              v-tooltip(right nudge-right="10px") Dashboard
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-dots-grid
              v-list-item-content
                v-list-item-title.font-weight-bold Dashboard

            v-list-item(:to="{ name: 'next-inventive-projects' }")
              v-tooltip(right nudge-right="10px") Inventive Projects
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-folder-multiple-outline
              v-list-item-content
                v-list-item-title.font-weight-bold Inventive Projects

            v-list-item(v-if="STANDARDS_ACCESS" :to="{ name: 'next-standards'}")
              v-tooltip(right nudge-right="10px") Standards
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-wifi
              v-list-item-content
                v-list-item-title.font-weight-bold Standards

            v-list-item(:to="{ name: 'next-opportunity-atlas' }" v-if="OPPORTUNITY_ATLAS_ENABLED")
              v-tooltip(right nudge-right="10px") Opportunity Atlas
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-flag-outline
              v-list-item-content
                v-list-item-title.font-weight-bold Opportunity Atlas

            v-list-item(:to="{ name: 'next-sense-engine' }")
              v-tooltip(right nudge-right="10px") Sense Engine
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-lightbulb-outline
              v-list-item-content
                v-list-item-title.font-weight-bold Sense Engine

            v-list-item(:to="{ name: 'next-prior-art' }")
              v-tooltip(right nudge-right="10px") Prior Art
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-selection-search
              v-list-item-content
                v-list-item-title.font-weight-bold Prior Art

            v-list-item(:to="{ name: 'next-invention-assistant' }")
              v-tooltip(right nudge-right="10px") Invention Assistant
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-creation-outline
              v-list-item-content
                v-list-item-title.font-weight-bold Invention Assistant

            v-list-item(:to="{ name: 'next-debug-prompts' }" v-if="USER_PROMPT_DEBUG_ENABLED" )
              v-tooltip(right nudge-right="10px") Debug Prompts
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-test-tube
              v-list-item-content
                v-list-item-title.font-weight-bold Debug Prompts

            v-divider

            v-list-item(href="https://iprova-jira.atlassian.net/servicedesk/customer/portal/2" target="_blank" tile)
              v-tooltip(right nudge-right="10px") Feedback
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-message-text-outline
              v-list-item-content
                v-list-item-title.font-weight-bold Feedback

            v-list-item(href="https://iprova-jira.atlassian.net/servicedesk/customer/portal/2/article/2281373697" target="_blank" tile)
              v-tooltip(right nudge-right="10px") User Guide
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-book-open-page-variant
              v-list-item-content
                v-list-item-title.font-weight-bold User Guide

            v-list-item(:to="{ name: 'dashboard' }")
              v-tooltip(right nudge-right="10px") Dashboard layout
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-home
              v-list-item-content
                v-list-item-title.font-weight-bold Dashboard layout

            v-divider
            v-spacer
            v-list-item
              v-tooltip(right nudge-right="20px") {{ themeToggleText }}
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-action.my-1.me-4(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    div.theme-mode-switch
                      v-switch(inset v-model="darkMode" dense id="darkModeSwitch")
                      span.icon(
                        :class="darkMode ? 'dark' : 'light'"
                        @click="darkMode = !darkMode"
                      )
              v-list-item-content
                v-list-item-title
                  label(for="darkModeSwitch").font-weight-bold {{ themeToggleText }}
            v-list-item(:to="{ name: 'next-profile-settings' }")
              v-tooltip(right nudge-right="10px") Settings
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-cog-outline
              v-list-item-content
                v-list-item-title.font-weight-bold Settings
            v-list-item(@click="logout()" tile)
              v-tooltip(right nudge-right="10px") Logout
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-logout
              v-list-item-content
                v-list-item-title.font-weight-bold Logout

        v-main(:class="[currentTheme, $router.currentRoute.name]")
          div.d-flex.align-center.header.header-wrapper.pt-5.pb-3.pr-6
            div.d-flex.align-center
              span.text-h4.text-no-wrap
                v-icon.ml-3.mr-1.mb-1.pa-3.menu-collapse(
                  size="24"
                  color="primary"
                  @click="mainMenuCollapsed = !mainMenuCollapsed"
                ) {{ mainMenuCollapsed ? 'mdi-menu' : 'mdi-close' }}
                span.ml-3 {{ $route.meta.title }}

            v-spacer

            div.d-flex.align-center.header-right
              invention-assistant-header-widget

              // User profile picture
              v-avatar(size="45")
                img.profile-img(v-if="profile.profile_picture" :src="profile.profile_picture" alt="Profile picture")
                v-gravatar(v-else :email="profile.email")

              v-btn.ml-2(
                icon
                outlined
                v-show="hasHelpsToShow"
                :disabled="!hasDismissedHelps"
                @click="showHelps()"
                small
              )
                v-icon(small) mdi-help

          slot(name="default")

        canvas-popover(
          v-if="currentProject"
          v-show="showPopover"
          :isShown="showPopover"
        )
        debug-langgraph-run-drawer(v-if="USER_PROMPT_DEBUG_ENABLED")

        main-snackbar
</template>

<script>
import MainSnackbar from '@/components/MainSnackbar.vue'
import { usePreferenceSyncedRef } from '@/utils/preferencesManager'
import Login from '@/modules/auth/views/Login'
import { loadLanguageAsync } from '@/plugins/i18n'
import { authStore } from '@/store/modules/auth'
import { projectsStore } from '@/modules/projects/store'
import { appStore } from '@/store/modules/app'
import { mapState, storeToRefs } from 'pinia'
import CanvasPopover from '../modules/canvas/views/CanvasPopover'
import { useDismissibleHelpStore } from '@/components/store/dismissibleHelpStore'
import { useFlagsStore } from '@/store/modules/flags'
import { useConfigStore } from '@/store/modules/configuration'
import CriticalError from '@/components/CriticalError.vue'
import { computed, onMounted } from 'vue'
import LOGO_LIGHT from './resources/is_logo_evo_light.svg'
import LOGO_DARK from './resources/is_logo_evo_dark.svg'
import LOGO_LIGHT_SMALL from './resources/iprova-icon-small-light.svg'
import LOGO_DARK_SMALL from './resources/iprova-icon-small-dark.svg'
import mapValues from 'lodash/mapValues'
import { DefaultFlagsValues } from '@/interfaces/flags'
import DebugLanggraphRunDrawer from '@/modules/agent/components/DebugLanggraphRunDrawer.vue'
import InventionAssistantHeaderWidget from '@/modules/agent/components/HeaderWidget.vue'

export default {
  components: {
    InventionAssistantHeaderWidget,
    DebugLanggraphRunDrawer,
    CriticalError,
    MainSnackbar,
    Login,
    CanvasPopover
  },
  inject: ['currentTheme'],
  provide() {
    const flagsStore = storeToRefs(useFlagsStore())
    return mapValues(DefaultFlagsValues, (v, k) => flagsStore[k])
  },
  setup() {
    const profileAuthStore = authStore()
    const { profile } = storeToRefs(profileAuthStore)
    const { fetchProfile } = profileAuthStore

    const mainMenuCollapsed = usePreferenceSyncedRef(false, 'MAIN_MENU_COLLAPSED', {})
    const darkMode = usePreferenceSyncedRef(false, 'CURRENT_THEME', {})

    onMounted(async function() {
      await Promise.all([
        loadLanguageAsync(),
        fetchProfile()
      ])
      this.setupLeaveGuard()
    })

    const userFullName = computed(() => `${profile.value.first_name} ${profile.value.last_name}`)

    const themeToggleText = computed(() => darkMode.value ? 'Light Mode' : 'Dark Mode')

    const iprovaLogo = computed(() => {
      return darkMode.value
        ? { large_logo: LOGO_DARK, small_logo: LOGO_LIGHT_SMALL }
        : { large_logo: LOGO_LIGHT, small_logo: LOGO_DARK_SMALL }
    })

    return {
      mainMenuCollapsed,
      darkMode,
      profile,
      userFullName,
      themeToggleText,
      iprovaLogo
    }
  },

  computed: {
    ...mapState(authStore, ['displayName', 'profile']),
    ...mapState(projectsStore, ['currentProject', 'currentProjectId']),
    ...mapState(useFlagsStore, ['INVENTION_TRACKER_ENABLED', 'OPPORTUNITY_ATLAS_ENABLED', 'STANDARDS_ACCESS', 'USER_PROMPT_DEBUG_ENABLED']),
    hasUser() {
      return Boolean(this.displayName)
    },
    configLoadingError() {
      return Boolean(useConfigStore().error)
    },
    viewTitle() {
      try {
        return this.$route.matched[0].instances?.default?.viewTitle || ''
      } catch (e) {
        // ignore
        return ''
      }
    },
    showPopover() {
      return Boolean(this.currentProject && this.$route.meta.hideCanvasPopover !== true)
    },
    hasHelpsToShow() {
      return useDismissibleHelpStore().availableHelp.length > 0
    },
    hasDismissedHelps() {
      return useDismissibleHelpStore().dismissedHelp.length > 0
    }
  },

  watch: {
    async hasUser(newValue) {
      if (newValue) {
        await projectsStore().getCurrent()
        if (this.$route.name === 'login') {
          const afterLogin = localStorage.getItem('afterLogin') || { name: 'home' }
          localStorage.removeItem('afterLogin')
          await this.$router.replace(afterLogin)
        }
      }
    },
    async $route(newRoute, oldRoute) {
      const oldProjectId = oldRoute?.params?.project_id
      const newProjectId = newRoute?.params?.project_id
      if (oldProjectId === newProjectId) return
      if (newProjectId === undefined) projectsStore().currentProject = null
      else await projectsStore().fetchCurrent(newProjectId)
    },
    viewTitle(newValue) {
      window.document.title = newValue || 'Invention Studio'
    },
    darkMode(value) {
      this.$vuetify.theme.isDark = value
      this.$vuetify.theme.applyTheme()
    }
  },
  methods: {
    showHelps() {
      useDismissibleHelpStore().clearDismissed()
    },
    async logout() {
      await authStore().logout()
      await this.$router.push({ name: 'login' })
    },
    setupLeaveGuard() {
      // Required to handle save changes confirmation on browser page reload.
      // The message specified bellow will be shown on older browsers,
      // new browsers will display their own generic message which cannot be overwritten
      window.addEventListener('beforeunload', (e) => {
        if (appStore().hasPendingChanges) {
          e.preventDefault()
          // chrome requires returnValue to be set
          const message = 'You have unsaved changes.\n\n' +
            'Are you sure you want to leave this page?'
          e.returnValue = message
          return message
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

.drawer-wrapper {
  height: 100vh;

  .v-list-item {
    flex: 0;
  }

  .v-spacer {
    flex: 1 !important;
  }
}

.profile-img {
  object-fit: cover !important;
}

.logo-wrapper {
  min-height: 80px;
  margin-left: 15px;

  .expanded-menu-logo {
    width: 100%;
    margin-right: 0;
    height: 75px;
  }

  .software-version {
    color: #9E9E9E;
    font-size: 13px;
    font-weight: 500;
    line-height: 150%;
    position: absolute;
    top: 58px;
    left: 72px;
  }
}

.logo-wrapper-collapsed {
  min-height: 50px;
  margin: auto;
}

.iprova-icon {
  width: 100%;
}

.iprova-icon-small {
  width: 35px;
  height: 35px;
  display: block;
  background-position: center;
  background-size: contain;
}

.header-wrapper {
  position: relative;

  @media (max-width: 1200px) {
    position: unset;
  }
}

.v-list-item {
  margin-bottom: 0 !important;

  &::before {
    border-radius: 0 !important;
  }
}

.v-card {
  overflow: auto;
}

.v-toolbar__content {
  max-width: 1845px;
  margin: 0 auto;
}

.v-tooltip__content.menuable__content__active {
  opacity: 1 !important;
}

.theme--light.v-btn.elevation-0:not(.v-btn--text):not(.v-btn--outlined) {
  border: thin solid rgba(0, 0, 0, 0.12);
}

.theme--dark.v-btn.elevation-0:not(.v-btn--text):not(.v-btn--outlined) {
  border: thin solid rgba(255, 255, 255, 0.12);
}

.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) {
  background: none;
}

.gutter.gutter {
  background-color: rgba(128, 128, 128, 0.3)
}

.v-pagination.theme--light button {
  border-radius: 0;
  border: thin solid rgba(0, 0, 0, 0.12);
  box-shadow: none;
}

.v-pagination.theme--dark button {
  border-radius: 0;
  border: thin solid rgba(255, 255, 255, 0.12);
  box-shadow: none;
}

[tile][tile][tile][tile][tile] {
  border-radius: 0;

  &::before, &::after {
    border-radius: 0;
  }
}

.v-expansion-panel::before {
  display: none;
}

.menu-collapse {
  z-index: 1;
  position: relative;
}

.theme-mode-switch {
  position: relative;
  display: inline-flex;
  align-items: center;

  .icon {
    position: absolute;
    top: 11px;
    left: 4px;
    transform: translateY(-50%);
    transition: left 0.3s ease;
    height: 14px;
    width: 14px;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .dark {
    left: 24px;
    background-image: url('resources/night.svg');
  }

  .light {
    left: 2px;
    background-image: url('resources/sun.svg');
  }
}

// theme mode switch styles
.theme--light {
  .v-icon,
  .v-list-item {
    color: var(--v-iprovaDark-base) !important;

    &:hover {
      &:before {
        border-radius: 20px !important;
      }
    }
  }

  .v-list-item--active,
  .v-list-item--active .v-icon {
    color: var(--v-iprovaLight-lighten7) !important;
  }

  .v-list-item--active {
    border-radius: 20px;
    background-color: var(--v-iprovaLight-lighten6);

    &:before {
      border-radius: 20px !important;
    }
  }

  :deep(.v-input--switch__track) {
    background: var(--v-iprovaDark-base);
  }

  :deep(.invention-assistant-text-field > .v-input__control > .v-input__slot) {
    background-color: #f6f6f6 !important;
  }
}

.navigation-drawer {
  &:not(.v-navigation-drawer--mini-variant) {
    .v-list-item {
      margin: 0 10px;
    }
  }

  :deep(.v-navigation-drawer__content) {
    scrollbar-width: thin;
  }
}

.header-right {
  position: relative;
}
</style>

<style lang="scss">
.d-contents {
  display: contents;
}

.textIprovaLight {
  color: var(--v-secondary-base);
}

.textIprovaDark {
  color: var(--v-primary-base);
}

span.search-highlight {
  background-color: rgba(255, 255, 0, 0.4);
  font-weight: bolder;
  white-space: nowrap;
}

.rounded.rounded.rounded {
  border-radius: 15px !important;
}

</style>
