// Register a global custom directive called `v-focus`
import { useAgentStore } from '@/modules/agent/store/useAgentStore';
import Vue from 'vue';
export const onscreenVisibleForAgentDataDirective = Vue.directive('OnscreenVisibleForAgentData', {
    inserted: function (el, binding) {
        // Focus the element
        const store = useAgentStore();
        // @ts-ignore
        const valueToProvide = () => el.checkVisibility() ? binding.value : undefined;
        // @ts-ignore
        const uid = String(el.__vue__._uid);
        Vue.set(store.onscreenContent, uid, valueToProvide);
    },
    unbind: function (el) {
        // @ts-ignore
        Vue.delete(useAgentStore().onscreenContent, String(el.__vue__._uid));
    }
});
