import { render, staticRenderFns } from "./DeleteTrackedTriggerDialog.vue?vue&type=template&id=774d7856&scoped=true&lang=pug"
import script from "./DeleteTrackedTriggerDialog.vue?vue&type=script&lang=ts"
export * from "./DeleteTrackedTriggerDialog.vue?vue&type=script&lang=ts"
import style0 from "./DeleteTrackedTriggerDialog.vue?vue&type=style&index=0&id=774d7856&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "774d7856",
  null
  
)

export default component.exports