export default [
  {
    path: '/next/prior-art',
    name: 'next-prior-art',
    meta: {
      title: 'Prior Art',
      nextRoute: true
    },
    component: () => import(/* webpackChunkName: "contextpriorart" */ '@/modules/prior_art/views/PriorArt')
  }
]
