export default [
  {
    path: '/next/invention-assistant',
    name: 'next-invention-assistant',
    meta: {
      title: 'Invention Assistant',
      nextRoute: true
    },
    component: () => import(/* webpackChunkName: "contextinventionassistant" */ '@/modules/agent/views/InventionAssistant')
  },
  {
    path: '/next/debug-prompts',
    name: 'next-debug-prompts',
    meta: {
      title: 'Debug Prompts',
      nextRoute: true
    },
    component: () => import(/* webpackChunkName: "contextinventionassistant" */ '@/modules/agent/views/DebugPrompts')
  }
]
