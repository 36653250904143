import { createTask, getRun } from '@/api/agent';
import { WS_HOST } from '@/environment';
import { useAgentTreeStateStore } from '@/modules/agent/store/useAgentTreeStateStore';
import { projectsStore } from '@/modules/projects/store';
import { makeReconnectingWebsocket } from '@/utils/websockets';
import { filter } from 'lodash';
import isString from 'lodash/isString';
import mapValues from 'lodash/mapValues';
import { defineStore } from 'pinia';
import { ref } from 'vue';
export const useAgentStore = defineStore('useAgentStore', () => {
    const agentTreeStateStore = useAgentTreeStateStore();
    const projectStore = projectsStore();
    const task = ref();
    const taskText = ref('');
    const websocket = ref();
    const currentAgentOutput = ref([]);
    const messages = ref([]);
    const waitingForInput = ref(true);
    const onscreenContent = ref({});
    // @ts-ignore
    window.computeOnScreenContent = () => {
        return Object.values(onscreenContent.value).map(f => f()).filter(Boolean);
    };
    async function startTask() {
        if (!taskText.value)
            return;
        waitingForInput.value = false;
        if (websocket.value?.ws) {
            currentAgentOutput.value = [];
            messages.value = [];
            websocket.value.ws.send(`${taskText.value}:::${agentTreeStateStore.lastRunId}`);
            taskText.value = '';
            return;
        }
        messages.value = [];
        task.value = (await createTask()).data;
        const onNewMessage = async (msg) => {
            if (!isString(msg.data))
                return;
            const [action, value] = msg.data.split(':::');
            if (value === undefined) {
                currentAgentOutput.value.push(msg.data);
            }
            else if (action === 'new_message') {
                const messageContent = JSON.parse(value);
                if (messageContent.type === 'suggested_tool' && !messageContent.id)
                    return;
                const index = messages.value.findIndex(message => message.id === messageContent.id);
                if (index >= 0) {
                    messages.value[index] = messageContent;
                }
                else {
                    messages.value.push(messageContent);
                }
                if (messageContent.type === 'ai') {
                    currentAgentOutput.value = [];
                }
            }
            else if (action === 'new_agent_run') {
                const runData = (await getRun(value)).data;
                agentTreeStateStore.allRuns.push(runData);
                agentTreeStateStore.lastRunId = value;
                messages.value = [];
                waitingForInput.value = true;
            }
            else if (action === 'get_user_context') {
                const userContext = {
                    project_id: projectStore.currentProjectId,
                    project_name: projectStore.currentProject?.name,
                    project_topic: projectStore.currentProject?.topic,
                    onscreen_content: filter(mapValues(onscreenContent.value, (value) => value()))
                };
                websocket.value?.ws?.send(`user_context:::${JSON.stringify(userContext)}`);
            }
            else {
                console.error('Unsupported message', msg.data);
            }
        };
        websocket.value = makeReconnectingWebsocket(`${WS_HOST}/api/projects/agent_task/${task.value?.id}`, (ws) => {
            ws.addEventListener('open', () => {
                ws.send(`${taskText.value}:::`);
                taskText.value = '';
            });
            ws.addEventListener('message', onNewMessage);
        });
    }
    return {
        task,
        taskText,
        messages,
        waitingForInput,
        currentAgentOutput,
        startTask,
        onscreenContent
    };
});
