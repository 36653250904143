import http from '../plugins/http';
const ENDPOINT = 'projects';
export function getProjects() {
    return http.get(ENDPOINT);
}
export function getProject(projectId) {
    return http.get(`${ENDPOINT}/${projectId}/`);
}
export function createProject(data) {
    return http.post(`${ENDPOINT}/`, data);
}
export function partialUpdateProject(projectId, data) {
    return http.patch(`${ENDPOINT}/${projectId}/`, data);
}
export function getProjectsTrackedTriggers(params = {}) {
    return http.get(`${ENDPOINT}/tracked_triggers`, { params });
}
export function getProjectUserAssignments(projectId) {
    return http.get(`${ENDPOINT}/${projectId}/user_assignments`);
}
export function patchProjectUserAssignments(projectId, data) {
    return http.patch(`${ENDPOINT}/${projectId}/user_assignments`, data);
}
export function getProjectUsers(projectId) {
    return http.get(`${ENDPOINT}/${projectId}/project_users/`);
}
export function getAllProjectsStatistics() {
    return http.get(`${ENDPOINT}-stats/`);
}
export function deleteProject(projectId) {
    return http.delete(`${ENDPOINT}/${projectId}/`);
}
export function createUploadedFile(projectId, file) {
    const formData = new FormData();
    formData.append('document', file);
    formData.append('project', projectId);
    return http.post(`${ENDPOINT}-documents/`, formData);
}
export function deleteUploadedFile(documentId) {
    return http.delete(`${ENDPOINT}-documents/${documentId}`);
}
export function patchProjectContext(itemId, data) {
    return http.patch(`${ENDPOINT}-context/${itemId}`, data);
}
