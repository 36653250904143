import { getLanggraphRun } from '@/api/agent';
import { defineStore } from 'pinia';
import { reactive, ref } from 'vue';
export const useDebugLanggraphRunStore = defineStore('debug-langgraph-run', () => {
    const open = ref(false);
    const runData = ref([]);
    const showRun = async (runId) => {
        runData.value = [];
        open.value = true;
        while (runId) {
            const res = await getLanggraphRun(runId);
            runData.value = res.data.messages.concat(runData.value);
            runId = res.data.parent_id;
        }
    };
    const state = ref({ open, runData });
    const actions = reactive({ showRun });
    return { state, actions };
});
