import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { getInventionConcept, patchInventionConcept } from '../../../api/inventionConcepts';
import cloneDeep from 'lodash/cloneDeep';
import pickBy from 'lodash/pickBy';
import isEqual from 'lodash/isEqual';
import { useInventionConceptsStore } from './inventionConceptsStore';
import store from '../../../store';
import { projectsStore } from '../../projects/store';
import omit from 'lodash/omit';
import { useUsersStore } from '../../users/store';
import differenceBy from 'lodash/differenceBy';
import debounce from 'lodash/debounce';
import { patchCandidateIdea } from '@/api/ideaGeneration';
import { ideaCreationSpaceStore } from '@/modules/candidate_ideas/store/ideaCreationSpace';
export const useSelectedInventionConceptStore = defineStore('selected-invention-concept', () => {
    const projectStore = projectsStore();
    const usersStore = useUsersStore();
    const ideaCreationStore = ideaCreationSpaceStore();
    const selectedIc = ref({});
    const icDataToPatch = ref({});
    const icStore = useInventionConceptsStore();
    const inventorToAdd = ref({});
    const inventorIds = ref([]);
    const allICInventors = computed({
        get: () => [icDataToPatch.value.owner, ...(icDataToPatch.value.inventors ?? [])],
        set: (value) => {
            if (!value.length)
                throw new Error('Inventors cannot be empty');
            icDataToPatch.value.owner_id = value[0].id;
            icDataToPatch.value.owner = value[0];
            icDataToPatch.value.inventors = value.slice(1, value.length);
            inventorIds.value = icDataToPatch.value.inventors.map(inventor => inventor.id);
        }
    });
    const mainTriggers = computed(() => {
        return icDataToPatch.value.tracked_triggers
            .filter(trigger => trigger.essential_features_ic
            .some(feature => feature.is_main_trigger))
            .map(trigger => ({
            ...trigger,
            essential_features_ic: trigger.essential_features_ic
                .filter(feature => feature.is_main_trigger && feature.invention_concept === selectedIc.value.id)
        }));
    });
    const supportingTriggers = computed(() => {
        return icDataToPatch.value.tracked_triggers.map(trigger => ({
            ...trigger,
            essential_features_ic: trigger.essential_features_ic
                .filter(feature => !feature.is_main_trigger && feature.invention_concept === selectedIc.value.id)
        })).filter(trigger => trigger.essential_features_ic.length);
    });
    const getSelectedIc = async (id) => {
        const responseIc = await getInventionConcept(id);
        if (responseIc?.data) {
            selectedIc.value = responseIc.data;
            icDataToPatch.value = cloneDeep(responseIc.data);
        }
    };
    const addInventorToIc = (inventor) => {
        allICInventors.value = [...allICInventors.value, inventor];
        inventorToAdd.value = {};
    };
    const removeInventor = (inventor) => {
        allICInventors.value = allICInventors.value.filter(inventorInIc => inventorInIc.id !== inventor.id);
    };
    /**
     * Update the generated idea with the new data from the IC connected with that idea
     */
    const updateGeneratedIdea = async (data) => {
        if (!selectedIc.value.candidate_idea_id)
            return;
        if (!ideaCreationStore.ideas?.length) {
            await ideaCreationStore.fetchIdeasForCurrentProject();
        }
        const candidateIdea = ideaCreationStore.ideas.find(idea => idea.uuid === selectedIc.value.candidate_idea_id);
        const payload = {};
        if (data.problem || data.concept || data.title) {
            payload.content = {
                title: data?.title ?? selectedIc.value?.title ?? '',
                concept: data?.concept ?? selectedIc.value?.concept ?? '',
                problem: data?.problem ?? selectedIc.value?.problem ?? '',
                extra_info_by_type: candidateIdea?.content?.extra_info_by_type ?? ''
            };
        }
        if (data.must_not_match)
            payload.must_not_match = data.must_not_match;
        if (data.tracked_triggers) {
            payload.triggers = data.tracked_triggers.flatMap((tt) => tt.essential_features_ic.map((efic) => ({
                url: tt.url,
                text: efic.content,
                assignee: tt.assignee,
                doc_type: tt.doc_type.toUpperCase(),
                doc_title: efic.invention_concept_title,
                is_main_trigger: efic.is_main_trigger,
                trigger_type: efic.type,
                location_x: efic?.location_x ?? undefined,
                location_y: efic?.location_y ?? undefined
            })));
        }
        await patchCandidateIdea(selectedIc.value.candidate_idea_id, payload);
    };
    const updateIc = async (newData) => {
        if (!selectedIc.value.id)
            return;
        // @ts-ignore
        let data = pickBy(icDataToPatch.value, (value, key) => !isEqual(value, selectedIc.value[key]));
        data = omit(data, ['inventors', 'owner']);
        if (inventorIds.value.length) {
            data.inventors_ids = inventorIds.value;
        }
        else if (selectedIc.value.inventors?.length && !inventorIds.value.length) {
            data.inventors_ids = [];
        }
        const dataToUpdate = newData ?? data;
        // no need to update if there is no data to update
        if (!Object.keys(dataToUpdate).length)
            return;
        try {
            await patchInventionConcept(selectedIc.value.id, dataToUpdate);
            await updateGeneratedIdea(dataToUpdate);
            await icStore.getAllInventionConcepts();
        }
        catch (error) {
            store.commit('snackbar/show', {
                color: 'error',
                message: 'Something went wrong. Please try again.'
            });
        }
    };
    const debounceUpdateIc = debounce(updateIc, 500);
    const revertIcChanges = async () => {
        const tempICForUndo = cloneDeep(icDataToPatch.value);
        icDataToPatch.value = cloneDeep(selectedIc.value);
        // @ts-ignore
        const dataToUpdate = omit(pickBy(icDataToPatch.value, (value, key) => !isEqual(value, tempICForUndo[key])), ['inventors', 'owner']);
        await updateIc(dataToUpdate);
        store.commit('snackbar/show', {
            color: 'success',
            message: 'IC changes reverted',
            extraActions: [{
                    title: 'Undo',
                    action: () => {
                        icDataToPatch.value = cloneDeep(tempICForUndo);
                    }
                }]
        });
    };
    const updateICCanvasBlocks = async (icId, canvasBlockIds) => {
        try {
            await patchInventionConcept(icId, { invention_workbook_blocks_ids: canvasBlockIds });
        }
        catch (error) {
            store.commit('snackbar/show', {
                color: 'error',
                message: 'Adding canvas blocks to IC failed.'
            });
        }
    };
    const usersInProject = computed(() => {
        return differenceBy(projectStore.currentProjectUsers?.map(userInProject => {
            const user = usersStore.allUsers?.find(user => user.id === userInProject.id);
            return user || null;
        }), allICInventors.value, 'id').filter(user => user?.is_active);
    });
    const nothingToSave = computed(() => {
        return isEqual(icDataToPatch.value, selectedIc.value);
    });
    const toggleBlockFolded = async (blockId) => {
        icDataToPatch.value.invention_workbook_blocks = icDataToPatch.value.invention_workbook_blocks
            .map(block => block.id === blockId ? { ...block, folded: !block.folded } : block);
    };
    watch(selectedIc, async () => {
        icDataToPatch.value = cloneDeep(selectedIc.value);
    });
    watch(() => projectsStore().currentProjectId, () => {
        icDataToPatch.value = {};
        selectedIc.value = {};
    });
    watch(icDataToPatch, () => debounceUpdateIc(), { deep: true });
    watch(inventorIds, () => debounceUpdateIc());
    return {
        selectedIc,
        icDataToPatch,
        inventorToAdd,
        inventorIds,
        nothingToSave,
        usersInProject,
        allICInventors,
        removeInventor,
        addInventorToIc,
        getSelectedIc,
        updateIc,
        revertIcChanges,
        updateICCanvasBlocks,
        toggleBlockFolded,
        mainTriggers,
        supportingTriggers,
        updateGeneratedIdea // for tests
    };
});
