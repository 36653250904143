import range from 'lodash/range';
export const TRACKED_TRIGGER_SOURCE = {
    OPP_ATLAS: 'Opportunity Atlas',
    INV_CANVAS_EXTERNAL: 'Canvas added manually',
    INV_CANVAS_TRIGGER_HUNTING_WIDGET: 'Canvas triggers widget',
    EVO_TRIGGER_TABLE: 'evo trigger table',
    EVO_REFINEMENT_TOOL: 'evo refinement tool',
    EVO_CONCEPT_RELEVANT_TRIGGERS: 'evo concept relevant triggers',
    EVO_ASSESSMENT_RELATED_DOCUMENTS: 'evo assessment related documents',
    DASHBOARD_SUGGESTED_TRIGGERS: 'dashboard suggested triggers'
};
export const STANDARDS_STATUSES = {
    AGREED: 'Agreed',
    APPROVED: 'Approved',
    AVAILABLE: 'Available',
    BLANK: 'Blank',
    CONDITIONALLY_AGREED: 'Conditionally agreed',
    CONDITIONALLY_APPROVED: 'Conditionally approved',
    ENDORSED: 'Endorsed',
    MERGED: 'Merged',
    NOT_CONCLUDED: 'Not concluded',
    NOT_PURSUED: 'Not pursued',
    NOT_TREATED: 'Not treated',
    NOTED: 'Noted',
    PARTIALLY_APPROVED: 'Partially approved',
    POSTPONED: 'Postponed',
    REISSUED: 'Reissued',
    REJECTED: 'Rejected',
    REPLIED_TO: 'Replied to',
    RESERVED: 'Reserved',
    REVISED: 'Revised',
    TREATED: 'Treated',
    WITHDRAWN: 'Withdrawn'
};
export const STANDARDS_STATUSES_COLORS = {
    NOTED: '#BB47F1',
    REVISED: '#FF8700',
    AGREED: '#00C13A',
    AVAILABLE: '#00C13A',
    NOT_TREATED: '#FF8700',
    APPROVED: '#00C13A',
    WITHDRAWN: '#EC1414',
    ENDORSED: '#00C13A',
    POSTPONED: '#BB47F1',
    MERGED: '#BB47F1',
    NOT_PURSUED: '#EC1414',
    RESERVED: '#FF8700',
    REPLIED_TO: '#FF8700',
    TREATED: '#00C13A',
    PARTIALLY_APPROVED: '#BB47F1',
    REJECTED: '#EC1414',
    NOT_CONCLUDED: '#EC1414',
    CONDITIONALLY_AGREED: '#FF8700',
    REISSUED: '#00C13A',
    CONDITIONALLY_APPROVED: '#FF8700',
    BLANK: '#EC1414'
};
export const STANDARDS_RELEASE_OPTIONS = range(1, 21).map(i => i.toString());
export const MAX_TOPICS_DISPLAY = 15;
export const STANDARDS_FILTERS = {
    Agenda_Item: 'standards_metadata.agenda_item',
    Group: 'standards_metadata.group',
    Meeting: 'standards_metadata.meeting',
    Release: 'standards_metadata.release',
    Source: 'standards_metadata.sources',
    Status: 'standards_metadata.status',
    Topic: 'topics_candidates',
    Type: 'standards_metadata.doc_type',
    Work_Item: 'standards_metadata.study_work_item'
};
export const STANDARDS_SEARCH_FILTERS = {
    Agenda_Item: 'standards_agenda_items',
    Group: 'standards_groups',
    Meeting: 'standards_meetings',
    Release: 'standards_releases',
    Source: 'standards_sources',
    Status: 'standards_statuses',
    Topic: 'standards_topics',
    Type: 'standards_doc_types',
    Work_Item: 'standards_study_work_items'
};
export const KEYWORD_SEARCH_ACTION = 'MORE_LIKE_THIS';
export const EXACT_SEARCH_ACTION = 'MATCH_PHRASE';
// semantic is either hybrid or ann based on context
export const SEMANTIC_SEARCH_ACTION = 'semantic';
export const ANN_SEARCH_ACTION = 'ANN';
export const HYBRID_SEARCH_ACTION = 'HYBRID';
export const MATCH_BY_VECTOR_CHOICES = [
    { text: 'Title', value: 'document' },
    { text: 'Advance', value: 'ability' },
    { text: 'Problem', value: 'problem' },
    { text: 'Trend', value: 'trend' }
];
export const KEYWORD_SEARCH_FIELDS = {
    Any: undefined,
    Title: ['title'],
    'Document ID': ['standards_metadata.tdoc_id'],
    Content: ['content'],
    'Essential Features': ['essential_features.text']
};
