export default [
    {
        path: '/project/new',
        name: 'project-new',
        meta: {
            title: 'Explore Concepts'
        },
        component: () => import(/* webpackChunkName: "projectform" */ '@/modules/projects/views/ProjectForm.vue')
    },
    {
        path: '/project/:project_id/edit',
        name: 'project-edit',
        meta: {
            title: 'Explore Concepts',
            hideCanvasPopover: true
        },
        component: () => import(/* webpackChunkName: "projectform" */ '@/modules/projects/views/ProjectForm.vue')
    },
    {
        path: '/project/:project_id/explore-ideas/table',
        name: 'explore-ideas',
        meta: {
            title: 'Explore Concepts'
        },
        component: () => import(/* webpackChunkName: "projecthome" */ '@/modules/projects/views/ProjectHome.vue')
    },
    {
        path: '/project/:project_id/explore-ideas/atlas',
        name: 'explore-ideas-atlas',
        meta: {
            title: 'Explore Concepts'
        },
        component: () => import(/* webpackChunkName: "projecthome" */ '@/modules/projects/views/ProjectHome.vue')
    },
    {
        path: '/next/projects',
        name: 'next-inventive-projects',
        meta: {
            title: 'Inventive Projects',
            nextRoute: true
        },
        component: () => import(/* webpackChunkName: "inventive-projects" */ '@/modules/projects/views/InventiveProject.vue')
    },
    {
        path: '/next/projects/new',
        name: 'next-project-new',
        meta: {
            title: 'New Project',
            nextRoute: true
        },
        component: () => import(/* webpackChunkName: "projectstepdefine" */ '@/modules/projects/views/ProjectStepDefine.vue')
    },
    {
        path: '/next/projects/:project_id/edit',
        name: 'next-project-edit',
        meta: {
            title: 'Edit Project',
            nextRoute: true
        },
        component: () => import(/* webpackChunkName: "projectstepdefine" */ '@/modules/projects/views/ProjectStepDefine.vue')
    },
    {
        path: '/next/projects/:project_id/explore',
        name: 'next-project-explore',
        meta: {
            title: 'Explore Concepts',
            nextRoute: true
        },
        component: () => import(/* webpackChunkName: "projecthome" */ '@/modules/projects/views/ProjectStepExplore.vue')
    }
];
