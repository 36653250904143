export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    alias: '/',
    components: {
      default: () => import(/* webpackChunkName: "dashboard" */ '@/modules/dashboard/views/DashboardView'),
      search: () => import(/* webpackChunkName: "dashboard" */ '@/modules/dashboard/views/DashboardSearch')
    }
  },
  {
    path: '/next',
    name: 'next',
    meta: {
      title: 'Dashboard',
      nextRoute: true
    },
    components: {
      default: () => import(/* webpackChunkName: "context-dashboard-view" */ '@/modules/dashboard/views/ContextDashboardView')
    }
  }
]
