import axios from 'axios';
import { filter, flatMap, map } from 'lodash/collection';
import { isArray, isPlainObject } from 'lodash/lang';
import { API_HOST } from '../environment';
import store from '../store';
import { useConfigStore } from '../store/modules/configuration';
import getCookie from '../utils/getCookie';
const API_URL = `${API_HOST}/api/`;
function explodeWhenTestingWithJest() {
    if (process.env.JEST_WORKER_ID !== undefined) {
        // note: this error may be caught by client code, but under normal circumstances
        // we should still get a big fat error in the console
        throw new Error('You should not use http in tests, use a mock instead.');
    }
}
const http = axios.create({
    baseURL: API_URL,
    timeout: 30000,
    headers: {
        common: {
            Accept: 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': getCookie('csrftoken')
        }
    },
    withCredentials: true
});
/**
 * Turns a params object such as { a: 1, b: [1, 2, 3]} into an URLSearchParams() that will search
 * ?a=1&b=1&b=2&b=3
 *
 * We do this because axios will take that same object and turn it into ?a=1&b[]=1&b[]=2&b[]=3
 *
 * See https://stackoverflow.com/questions/42898009/multiple-fields-with-same-key-in-query-params-axios-request/43208627
 * for more information
 */
function toUrlSearchParams(searchParams) {
    return new URLSearchParams(filter(flatMap(searchParams, (value, key) => isArray(value)
        ? map(value, (elem) => [key, elem])
        : [[key, value]]), (value) => value[1] !== undefined));
}
/**
 * Intercept HTTP request before it's sent.
 * Add a slash at the end if it is missing.
 */
export async function interceptRequest(config) {
    explodeWhenTestingWithJest();
    const source = axios.CancelToken.source();
    config.cancelToken = source.token;
    if (config.url.slice(-1) !== '/') {
        config.url += '/';
    }
    if (config.method === 'get' && isPlainObject(config.params)) {
        config.params = toUrlSearchParams(config.params);
    }
    return config;
}
http.interceptors.request.use(interceptRequest, error => Promise.reject(error));
let logOnce = false;
function refreshSpaOnNewVersion(response) {
    const backendIsVersion = response.headers['invention-studio-version'];
    const envVersion = useConfigStore().INVENTION_STUDIO_VERSION;
    if (envVersion && backendIsVersion > envVersion) {
        const lastBackendIsVersion = localStorage.getItem('lastBackendIsVersion');
        if (lastBackendIsVersion !== backendIsVersion) {
            localStorage.setItem('lastBackendIsVersion', backendIsVersion);
            location.reload();
        }
        else if (!logOnce) {
            logOnce = true;
            console.error('New backend version not matched by new SPA version!');
        }
    }
}
// Intercept HTTP response success and clean the request from currentExecutingRequestsr
export async function interceptResponseSuccess(response) {
    refreshSpaOnNewVersion(response);
    return response;
}
/**
 * Intercept HTTP response error and logout if server returns authentication error
 * otherwise show error snackbar
 */
export async function interceptResponseError(err) {
    if (err.constructor === axios.Cancel) {
        // check if this is a cancelled request drop it silently (without error)
        // eslint-disable-next-line
        // @ts-ignore
        if (!err.Cancel?.message) {
            return new Promise(() => {
            });
        }
        return Promise.reject(err);
    }
    const { config } = err;
    const status = err.request?.status;
    let message = null;
    if (!status) {
        message = `Oops! Something went wrong. Could not reach the server.
               Try to refresh the page.
               If it persists, please contact your administrator.`;
    }
    if (status === 401) {
        return Promise.reject(err);
    }
    if (status === 403) {
        message = 'You do not have the necessary permissions to perform this action.';
    }
    if (status > 500) {
        message = `Oops! Something went wrong. A server error has occured.
               Try to refresh the page.
               If it persists, please contact your administrator.`;
    }
    if (status === 404 && config?.method !== 'delete') {
        message = 'The item you\'re trying to access doesn\'t exist. Maybe it has been deleted?';
    }
    if (message) {
        store.commit('snackbar/show', {
            color: 'error',
            message
        });
    }
    return Promise.reject(err);
}
http.interceptors.response.use(interceptResponseSuccess, interceptResponseError);
export default http;
