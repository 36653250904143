import { listRuns } from '@/api/agent';
import { useAsyncState } from '@/utils/asyncState';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
export const useAgentTreeStateStore = defineStore('use-agent-tree-state-store', () => {
    const currentThread = ref('');
    const allRuns = useAsyncState(async () => {
        if (!currentThread.value)
            return [];
        return (await listRuns(currentThread.value)).data;
    }, [], {
        allowParallel: false
    });
    watch(allRuns.state, (newValue) => {
        if (!newValue.length) {
            lastRunId.value = undefined;
        }
        else {
            lastRunId.value = newValue[newValue.length - 1].id;
        }
    }, { deep: true });
    const lastRunId = ref();
    const lastRun = computed(() => {
        if (!lastRunId.value)
            return;
        return allRuns.state.value?.find(o => o.id === lastRunId.value);
    });
    return {
        lastRunId,
        allRuns: allRuns.state,
        lastRun
    };
});
