export default [
    {
        path: '/project/:project_id/review-select',
        name: 'review-select',
        meta: {
            title: 'Explore Concepts'
        },
        component: () => import(/* webpackChunkName: "reviewselect" */ '@/modules/invention_concepts/views/ReviewSelect.vue')
    },
    {
        path: '/next/project/:project_id/review-select',
        name: 'next-review-select',
        meta: {
            title: 'Explore Concepts',
            nextRoute: true
        },
        component: () => import(/* webpackChunkName: "reviewselect" */ '@/modules/invention_concepts/views/NextReviewSelect.vue')
    }
];
